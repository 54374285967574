import React from "react";
import { CardGroup, Card} from "react-bootstrap";
import "../news/news.css";
import Button from "../buttons/button"
import {Link} from "gatsby";

const News = ({ news } ) => {
    /*let news = [
        {
            newsBanner: "/imgs/articulo_gran-avance-vehiculos-autonomos.jpg",
            newsTitle: "El gran avance de los Vehículos Autónomos",
            newsTopic: "Tecnología",
            newsText: "<strong>Estamos en el inicio de una revolución digital dentro de la industria automovilística.</strong><br /> La movilidad del futuro abarca un amplio ecosistema de sectores que se beneficiarán con la producción y el éxito de los vehículos autónomos.",
            newsDate: "09/02/2022 ",
            slug: "gran-avance-vehiculos-autonomos",
            section: "investment-ideas",
            artid: 1
        },
        {
            newsBanner: "/imgs/articulo_donde-invertir-en-2022.jpg",
            newsTitle: "¿Dónde invertir dinero en 2022?",
            newsTopic: "Inversiones",
            newsText: "<strong>Oportunidades para optimizar el rendimientos de tus inversiones.</strong><br /> Los primeros meses del año suelen ser fundamentales en las planificaciones financieras de nuestros inversores.",
            newsDate: "05/01/2022 ",
            slug: "donde-invertir-en-2022",
            section: "investment-ideas",
            artid: 2
        },
        {
            newsBanner: "/imgs/articulo_que-es-5g-como-invertir-en-esta-tecnologia.jpg",
            newsTitle: "¿Qué es el 5G y cómo invertir en esta tecnología?",
            newsTopic: "Tecnología",
            newsText: "<strong>La comunicación entre los dispositivos se hace vital, y ahí es donde entra la tecnología 5G.</strong> 5G se refiere a la quinta generación de redes móviles. Esta tecnología brindará experiencias más rápidas y sólidas.",
            newsDate: "24/02/2022 ",
            slug: "que-es-5g-como-invertir-en-esta-tecnologia",
            section: "investment-ideas",
            artid: 3
        }
    ];*/
// console.log('news', news)
    return (
      <CardGroup className="newsContainer">
        {news.map((entry, key) => {
          return (
              <Card className="newsCard" key={key}>
                <div
                    className="newsBanner"
                    style={{
                      backgroundImage: `url(${entry.imgUrl})`,
                      backgroundSize: "cover",
                    }}
                >
                    <h5 className="card-title newsTitle">{<div dangerouslySetInnerHTML={ {__html: entry.title} } />}</h5>
                </div>
                <div className="card-body newsContent">
                    {entry.newsTopic !== undefined && (
                        <span className="newsTopic">{entry.topic}</span>
                    )}
                  <div className="card-text newsText">{<div dangerouslySetInnerHTML={ {__html: entry.htmldesc} } />}</div>

                </div>
                <div className="card-footer">

                    {(entry.section !== undefined && entry.slug !== undefined) ? (
                        <Link to={`/${entry.section}/${entry.slug}`}>
                            <Button variant="secondary newsButton" text="Ver más" />
                        </Link>
                    ) : (
                        <Link to="/investment-ideas/">
                            <Button variant="secondary newsButton" text="Ver más" />
                        </Link>
                    )}
                    {entry.date !== undefined && (
                        <p className="newsDate">{entry.date}</p>
                    )}

                </div>
              </Card>
          );
        })}
      </CardGroup>
    );
};

export default News;
